<template>
    <div class="container">
        <div class="grid justify-content-center">
            <div class="col-12 md:col-8 comp-grid">
                <div style="margin-top: 17vh;" class="my-3 card">
                    <div class="text-3xl text-center font-bold text-error my-3">
                        How to Apply
                    </div>
                    <div class="text-primary">
                        Please visit any branch of Consolidated Bank Ghana (CBG) to
                        purchase a <b>serial number</b> and <b>pin</b> for this online application.
                    </div>
                    <div class="text-primary">
                        <ul>
                            <li>Serial numbers are 8 characters <i>(Example: 2DJ358ND)</i> </li>
                            <li>Pins are 6 characters <i>(Example: SJ3875)</i> </li>
                        </ul>
                    </div>
                    <div class="text-pink-500">
                        After purchase, please ensure your serial number and pin are visibly printed on your bank receipt
                        before leaving the premises. If you noticed your receipt has an invalid serial number or pin, please
                        return to
                        the same Bank for correction.
                    </div>
                    <hr />
                    <div class="text-center">
                          <router-link to="/">
                        <Button label="Back to Home" class="px-4" icon="pi pi-home" />
                    </router-link>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {},
    data: function () {
        return {
        };
    },
    methods: {},
};
</script>